*, :before, :after {
  box-sizing: border-box;
}

ul, ol, menu {
  padding: 0;
}

body, h1, h2, h3, h4, p, ul, ol, menu, figure, blockquote, dl, dd {
  margin: 0;
}

body {
  scroll-behavior: smooth;
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

ul, ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}
/*# sourceMappingURL=index.344368c8.css.map */
